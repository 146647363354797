import useStore from '@/store';
import { ActionTypes } from '@/store/modules/vote/actions';
import { Vote } from '@/interfaces/vote';
import { computed, WritableComputedRef } from 'vue';
import { MutationTypes as TokenMutation } from '@/store/modules/token/mutations';
import { Token } from '@/interfaces/token';

export const useVote = () => {
  const store = useStore();

  const voteList: WritableComputedRef<Vote[]> = computed(
    () => store.getters.voteList
  );

  const isDisabled = (tokenId: string) => {
    return voteList.value.find((v) => v.tokenId === tokenId);
  };
  const addVote = async (vote: Vote) => {
    await store.dispatch(ActionTypes.addVote, vote);
    await store.commit(TokenMutation.VOTE_ADDED, vote);
  };
  const getVoteCountForToken = (token: Token) =>
    token.voteItemsCount ? token.voteItemsCount : token.voteCount;

  return {
    isDisabled,
    addVote,
    getVoteCountForToken,
  };
};
