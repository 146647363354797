
import { computed, defineComponent, PropType } from 'vue';
import { Token } from '@/interfaces/token';
import {
  IonItem,
  IonImg,
  IonButton,
  IonIcon,
  IonChip,
  IonLabel,
  IonCheckbox,
} from '@ionic/vue';
import { useVote } from '@/hooks/useVote';
import formatTimeAgo from '@/utils/format-time-ago';
import { arrowUpOutline } from 'ionicons/icons';
import { Vote } from '@/interfaces/vote';
import TokenItemStateLoading from '@/components/token-item/TokenItemStateLoading.vue';

export default defineComponent({
  name: 'TokenItem',
  emits: ['vote', 'check'],
  components: {
    IonItem,
    IonImg,
    IonButton,
    IonIcon,
    IonChip,
    IonLabel,
    IonCheckbox,
    TokenItemStateLoading,
  },
  props: {
    token: {
      type: Object as PropType<Token>,
    },
    count: { type: Number, default: 0 },
    showSinceLaunchDate: Boolean,
    showLaunchDate: Boolean,
    showSymbol: Boolean,
    manage: Boolean,
    checkbox: Boolean,
    checked: Boolean,
    stateLoading: Boolean,
  },
  setup(props) {
    const { isDisabled, addVote } = useVote();

    const launchDate = computed(() => {
      return new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'medium',
      }).format(props.token ? new Date(props.token.launchDate) : new Date());
    });

    const sinceLaunchDate = computed(() => {
      return formatTimeAgo(props.token ? props.token.launchDate : new Date());
    });

    const vote = async (v: Vote) => {
      await addVote(v);
    };

    return {
      launchDate,
      sinceLaunchDate,
      arrowUpOutline,

      isDisabled,
      vote,
    };
  },
});
