<template>
  <ion-thumbnail slot="start">
    <ion-skeleton-text></ion-skeleton-text>
  </ion-thumbnail>
  <ion-label>
    <h3>
      <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
    </h3>
    <p>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
    </p>
    <p>
      <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
    </p>
  </ion-label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonLabel, IonThumbnail, IonSkeletonText } from '@ionic/vue';

export default defineComponent({
  name: 'TokenItemStateLoading',
  components: { IonLabel, IonThumbnail, IonSkeletonText },
});
</script>

<style lang="scss" scoped>
h3,
p {
  padding: 0;
  margin: 0;
}
</style>
