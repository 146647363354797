<template>
  <ion-item
    class="token-item"
    :class="{ 'token-item--state-loading': stateLoading }"
    lines="none"
  >
    <TokenItemStateLoading v-if="stateLoading" />

    <div
      v-if="!stateLoading"
      class="token-item__left-col"
      :class="{
        'token-item__left-col--auto':
          (!showSinceLaunchDate && showSymbol) || manage,
      }"
    >
      <ion-img
        class="token-item__logo"
        :alt="token.label"
        :src="token.logo"
      ></ion-img>
      <div class="token-item__informations-wrapper">
        <span class="token-item__label" v-text="token.label" />
        <ion-chip
          v-if="showSymbol || manage"
          class="token-item__chip-symbol"
          color="medium"
        >
          <ion-label
            class="token-item__chip-symbol-label"
            v-text="token.symbol"
          />
        </ion-chip>
        <span
          v-if="showLaunchDate || manage"
          class="token-item__launch-date"
          v-text="launchDate"
        />
        <div v-if="manage" class="token-item__manage-informations">
          <ion-chip
            v-if="token.pushed"
            class="token-item__chip-promote"
            color="light"
          >
            <ion-label v-text="$t('manage-token.promoted')" />
          </ion-chip>
          <ion-chip
            v-if="token.published"
            class="token-item__chip-publish"
            color="success"
          >
            <ion-label v-text="$t('manage-token.published')" />
          </ion-chip>
        </div>
      </div>
    </div>
    <div v-if="!stateLoading" class="token-item__right-col">
      <span
        v-if="showSinceLaunchDate"
        class="token-item__since-launch-date"
        v-text="sinceLaunchDate"
      />
      <ion-button
        v-if="!manage"
        class="token-item__vote-button"
        color="success"
        fill="outline"
        :disabled="isDisabled(token._id)"
        @click="
          $event.stopPropagation();
          vote({ tokenId: token._id });
        "
      >
        <ion-icon slot="start" :icon="arrowUpOutline" />
        {{ count }}
      </ion-button>
    </div>

    <ion-checkbox
      v-if="checkbox"
      class="token-item__checkbox"
      slot="end"
      color="primary"
      @click="$event.stopPropagation()"
      @ionChange="$emit('check', { value: $event.detail.checked, token })"
      :value="checked"
    />
  </ion-item>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { Token } from '@/interfaces/token';
import {
  IonItem,
  IonImg,
  IonButton,
  IonIcon,
  IonChip,
  IonLabel,
  IonCheckbox,
} from '@ionic/vue';
import { useVote } from '@/hooks/useVote';
import formatTimeAgo from '@/utils/format-time-ago';
import { arrowUpOutline } from 'ionicons/icons';
import { Vote } from '@/interfaces/vote';
import TokenItemStateLoading from '@/components/token-item/TokenItemStateLoading.vue';

export default defineComponent({
  name: 'TokenItem',
  emits: ['vote', 'check'],
  components: {
    IonItem,
    IonImg,
    IonButton,
    IonIcon,
    IonChip,
    IonLabel,
    IonCheckbox,
    TokenItemStateLoading,
  },
  props: {
    token: {
      type: Object as PropType<Token>,
    },
    count: { type: Number, default: 0 },
    showSinceLaunchDate: Boolean,
    showLaunchDate: Boolean,
    showSymbol: Boolean,
    manage: Boolean,
    checkbox: Boolean,
    checked: Boolean,
    stateLoading: Boolean,
  },
  setup(props) {
    const { isDisabled, addVote } = useVote();

    const launchDate = computed(() => {
      return new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'medium',
      }).format(props.token ? new Date(props.token.launchDate) : new Date());
    });

    const sinceLaunchDate = computed(() => {
      return formatTimeAgo(props.token ? props.token.launchDate : new Date());
    });

    const vote = async (v: Vote) => {
      await addVote(v);
    };

    return {
      launchDate,
      sinceLaunchDate,
      arrowUpOutline,

      isDisabled,
      vote,
    };
  },
});
</script>

<style lang="scss" scoped>
.token-item {
  --padding-start: 0.5em;
  --padding-end: 0.5em;
  --padding-top: 0.5em;
  --padding-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--ion-color-gallery);
  --background: var(--ion-color-light);
  color: var(--ion-color-dark);
  cursor: pointer;

  &--state-loading {
    border: none;
  }

  &__left-col,
  &__right-col {
    flex: 1;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 0.5em;

    &--auto {
      flex: auto;
    }
  }

  &__logo {
    width: 3.5em;
    height: 3.5em;
    border-radius: 0.5em;
    overflow: hidden;
    object-fit: cover;
  }

  &__informations-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__manage-informations {
    display: flex;
    align-items: center;
  }

  &__chip-symbol {
    align-self: flex-start;
  }

  &__chip-symbol-label {
    text-transform: uppercase;
    font-weight: var(--fw-bold);
  }

  &__launch-date {
    font-size: 0.65em;
    font-weight: var(--fw-bold);
  }

  &__right-col {
    margin-left: 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'since-launch-date vote-button';
    text-align: center;
  }

  &__since-launch-date {
    font-size: 0.75em;
  }
  &__since-launch-date {
    grid-area: since-launch-date;
  }
  &__vote-button {
    grid-area: vote-button;
  }

  &__checkbox {
    position: absolute;
    right: 0;
  }
}
</style>
